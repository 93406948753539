// Importar las librerías necesarias
import React from 'react';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { Modal, Button, Form, Dropdown, DropdownButton } from 'react-bootstrap';
import './ModalEditPurchase.Module.css';
import toast from 'react-hot-toast';
import axios from 'axios';
import { getMediosPago, validateTotal, sumaMedios } from '../../../utils/puchases/purchases';
import { getUser } from '../../../utils/users/users';
import { getGiftcard } from '../../../utils/giftcards/giftcard';
import { currencyDisplay } from '../../../utils/cart/cart';
import { obtenerCostoProductosServicios } from '../../../utils/products/products';
import { isGiftCardExpired } from '../../../utils/giftcards/giftcard';
import { getStaffUsers } from '../../../utils/users/users';
// Definir el componente EditModal
const ModalEditPurchase = ({ show, handleClose, purchase }) => {

	

	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: token,
		},
	};
	const user = useSelector((state) => state.user.userInfo);
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
	const [staff, setStaff]=React.useState([])
	//-------------LOGICA PARA MEDIOS DE PAGO DISPONIBLES---------------------------
	const [mediosPago, setMediosPago] = React.useState([]);
	const [selectedMediosPago, setSelectedMediosPago] = React.useState([]); // Estado para los medios de pago seleccionados

	const [giftCardInfo, setGiftCardInfo] = React.useState({});
	const [giftCardCode, setGiftCardCode] = React.useState('');
	const [clientInfo, setClientInfo] = React.useState({});
	const [selectedBeneficio, setSelectedBeneficio] = React.useState(''); // Agrega el estado para el beneficio seleccionado

	const [subTotalConDescuento, setSubtotalConDescuento] = React.useState(0);
	const handleBeneficioChange = (e) => {
		const selectedBeneficioId = e.target.value;
		// Buscar el objeto completo del beneficio por su ID en los beneficios disponibles
		const beneficioSeleccionado = clientInfo?.beneficios?.find(
			(beneficio) => beneficio._id === selectedBeneficioId
		);
		setSelectedBeneficio(beneficioSeleccionado); // Actualiza el estado con el objeto completo del beneficio seleccionado
	};

	const handleMediosPagoChange = (medioPagoId) => {
		// Buscar el medio de pago por su id en la lista de mediosPago
		const medioPagoSeleccionado = mediosPago.find((medio) => medio._id === medioPagoId);

		if (medioPagoSeleccionado) {
			setSelectedMediosPago((prevSelectedMediosPago) => {
				// Verificar si el medio de pago ya existe en el estado
				const medioExistente = prevSelectedMediosPago.find(
					(medio) => medio.medioPago === medioPagoId
				);

				if (medioExistente) {
					return prevSelectedMediosPago.map((medio) =>
						medio.medioPago === medioPagoId
							? { ...medio, monto: '' } // Reiniciar el monto si el medio de pago ya existe
							: medio
					);
				} else {
					return [
						...prevSelectedMediosPago,
						{ medioPago: medioPagoId, nombre: medioPagoSeleccionado.nombre ||"" , monto: 0 },
					];
				}
			});
		}
	};

	const handleMontoMedioPagoChange = (e, index) => {
		const { value } = e.target;
		const updatedSelectedMediosPago = [...selectedMediosPago];
		updatedSelectedMediosPago[index].monto = value !== '' ? parseFloat(value) : '';
		setSelectedMediosPago(updatedSelectedMediosPago);
	};

	const handleRemoveMedioPago = (index) => {
		setSelectedMediosPago((prevSelectedMediosPago) => {
			// Filtra los medios de pago para mantener solo los que no coinciden con el índice
			return prevSelectedMediosPago.filter((_, i) => i !== index);
		});
	};

	const handleStaffSelect = (staffId)=>{
// console.log(staffId)
		setFormData((prevData) => ({
			...prevData,
			atendidoPor: staffId, // Actualiza el campo 'cliente' con el ID del usuario clickeado
		}));
	}
	const handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
	
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	React.useEffect(() => {
		const fetchMediosPago = async () => {
			try {
				const mediosPagoData = await getMediosPago(0, 20);
				setMediosPago(mediosPagoData.mediosDePago);
				const staffData = await getStaffUsers()
				setStaff(staffData)
			} catch (error) {
				console.log(error);
				toast.error('hubo un error obteniendo los medios de pago');
			}
		};

		fetchMediosPago();
	}, []);

	//------------------------------------------------------------------------------------------

	const [customPrices, setCustomPrices] = React.useState(
		purchase.detalle.map((producto) => parseFloat(producto.precioElegido))
	);

	const handleCustomPriceChange = (e, index) => {
		const { value } = e.target;
		const updatedCustomPrices = [...customPrices]; // Clonamos los precios personalizados
		updatedCustomPrices[index] = value !== '' ? parseFloat(value) : ''; // Actualizamos el precio personalizado clonado

		setCustomPrices(updatedCustomPrices); // Actualizamos el estado de los precios personalizados

		const updatedDetalle = [...formData.detalle]; // Clonamos el detalle actual
		updatedDetalle[index].precioElegido = value !== '' ? parseFloat(value) : 0; // Actualizamos el precioElegido en el detalle clonado

		setFormData((prevData) => ({
			...prevData,
			detalle: updatedDetalle, // Actualizamos el detalle en el estado
			total: calculateTotal(updatedDetalle),
			totalPuntos: calculateTotalPuntos(updatedDetalle),
		}));
	};

	const initialSelectedPrices = purchase.detalle.map((producto) => producto.precioElegido);
	const [selectedPrices, setSelectedPrices] = React.useState(initialSelectedPrices);

	const [selectedEstado, setSelectedEstado] = React.useState('');

	const handleQuantityChange = (e, index) => {
		const { value } = e.target;
		const updatedDetalle = [...formData.detalle];
		const newCantidad = value !== '' ? parseInt(value, 10) : 0;
		updatedDetalle[index].cantidad = newCantidad;
		setFormData((prevData) => ({
			...prevData,
			detalle: updatedDetalle,
			total: calculateTotal(updatedDetalle),
			totalPuntos: calculateTotalPuntos(updatedDetalle),
		}));
	};

	const handlePuntosChange = (e, index) => {
		const { value } = e.target;
		const updatedDetalle = [...formData.detalle];
		const newPuntos = value !== '' ? parseInt(value, 10) : 0;
		updatedDetalle[index].puntos = newPuntos;
		setFormData((prevData) => ({
			...prevData,
			detalle: updatedDetalle,
			total: calculateTotal(updatedDetalle),
			totalPuntos: calculateTotalPuntos(updatedDetalle),
		}));
	};

	const handlePriceChange = (e, index) => {
		const { value } = e.target;
		if (value === 'precioPersonalizado') {
			setIsCustomPriceSelected(index);
		} else {
			setIsCustomPriceSelected(false);
			setSelectedPrices((prevPrices) => {
				const updatedPrices = [...prevPrices];
				updatedPrices[index] = value;

				const updatedDetalle = [...formData.detalle];
				if (value === 'precioPersonalizado') {
					updatedDetalle[index].precioElegido = customPrices[index];
				} else {
					updatedDetalle[index].precioElegido = parseFloat(updatedDetalle[index][value]);
				}

				setFormData((prevData) => ({
					...prevData,
					detalle: updatedDetalle,
					total: calculateTotal(updatedDetalle),
					totalPuntos: calculateTotalPuntos(updatedDetalle),
				}));

				return updatedPrices;
			});
		}
	};

	const handleEstadoChange = (e) => {
		setSelectedEstado(e.target.value);
		setFormData((prevData) => ({
			...prevData,
			estadoCompra: e.target.value,
		}));
	};

	const calculateTotal = (detalle) => {
		return detalle.reduce((total, producto) => {
			return total + producto.precioElegido * producto.cantidad;
		}, 0);
	};

	const calculateTotalPuntos = (detalle) => {
		return detalle.reduce((total, producto) => {
			return total + producto.puntos * producto.cantidad;
		}, 0);
	};

	const [isCustomPriceSelected, setIsCustomPriceSelected] = React.useState(false);

	const [formData, setFormData] = React.useState({
		detalle: purchase?.detalle,
		total: calculateTotal(purchase?.detalle),
		totalPuntos: calculateTotalPuntos(purchase?.detalle),
		estadoCompra: purchase?.estadoCompra,
		mediosPago: [],
		beneficio: '',
		atendidoPor:purchase?.atendidoPor,
		notas:purchase?.notas
	});
const handleDelete = async () =>{
	try {
		await axios.delete(
			`${process.env.REACT_APP_BACK_URL}/api/compras/${purchase?._id}`,
			config
		);
		const event = new Event('actualizar-compra');
			window.dispatchEvent(event);
			toast.success('Compra ingresada/eliminada con éxito');
			setFormData({
				detalle: [],
				total: '',
				totalPuntos: '',
				estadoCompra: '',
				mediosPago: [], // Restablece también mediosPago aquí
				beneficio: '',
				atendidoPor:"",
			notas:""
			});

			handleClose();
	} catch (error) {
		toast.error("Error al eliminar la venta")
	}

}
	const handleEditPurchase = async () => {
		// Verifica si "GIFTCARD" está en los medios de pago seleccionados
		const isGiftCardSelected = selectedMediosPago.some(
			(medioPago) => medioPago.nombre === 'GIFTCARD'
		);

		const totalConsumidoEnGiftCard = isGiftCardSelected
			? selectedMediosPago.find((medioPago) => medioPago.nombre === 'GIFTCARD').monto
			: 0;
		// Agregar selectedMediosPago al formData
		const updatedFormData =
			subTotalConDescuento > 0
			? {
        ...formData,
        mediosPago: selectedMediosPago,
        total: subTotalConDescuento,
        beneficio: selectedBeneficio,
        atendidoPor: formData?.atendidoPor?._id // Asegúrate de incluir solo el uid aquí
      }
    : {
        ...formData,
        mediosPago: selectedMediosPago,
        atendidoPor: formData?.atendidoPor?._id // Asegúrate de incluir solo el uid aquí
      };


		try {
			await axios.put(
				`${process.env.REACT_APP_BACK_URL}/api/compras/${purchase?._id}`,
				updatedFormData,
				config
			);
			if (isGiftCardSelected) {
				const giftCardCodigo = giftCardCode; // Reemplaza esto por la forma de obtener el código de la gift card
				const saldoConsumido = totalConsumidoEnGiftCard;
				const estado =
					giftCardInfo.saldoDisponible - saldoConsumido === 0 ? 'UTILIZADA' : 'DISPONIBLE';

				await axios.put(
					`${process.env.REACT_APP_BACK_URL}/api/giftcards/${giftCardCodigo}`,
					{ estado, saldoConsumido },
					config
				);
				const event2 = new Event('actualizar-giftcard');
				window.dispatchEvent(event2);
			}
			const event = new Event('actualizar-compra');
			window.dispatchEvent(event);
			toast.success('Compra ingresada/eliminada con éxito');
			setFormData({
				detalle: [],
				total: '',
				totalPuntos: '',
				estadoCompra: '',
				mediosPago: [], // Restablece también mediosPago aquí
				beneficio: '',
				atendidoPor:"",
				notas:""
			});

			handleClose();
		} catch (error) {
			toast.error('Error al editar compra');
		}
	};

	const resetFormData = {
		detalle: [],
		total: '',
		totalPuntos: '',
		estadoCompra: '',
		mediosPago: [],
		beneficio: '',
	};

	const resetState = () => {
		setFormData(resetFormData);
	};


	React.useEffect(() => {
		const obtenerCliente = async () => {
			try {
				const idUser = purchase?.cliente?._id
				const clientData = await getUser(idUser);
				setClientInfo(clientData);
			} catch (error) {
				toast.error('hubo un error obteniendo el cliente');
			}
		};

		obtenerCliente();
	}, []);
	React.useEffect(() => {
		// Calcula el costo de productos y servicios con el beneficio seleccionado
		const costoConBeneficio = obtenerCostoProductosServicios(purchase?.detalle, selectedBeneficio);

		// Actualiza el estado de subTotalConDescuento con el costo calculado
		setSubtotalConDescuento(costoConBeneficio);
	}, [selectedBeneficio]);
	const handleVerifyGiftCard = async () => {
		try {
			const giftCardData = await getGiftcard(giftCardCode);
			setGiftCardInfo(giftCardData);
		} catch (error) {
			toast.error('Hubo un error obteniendo los datos de la gift card');
		}
	};

	return (
		<Modal
			show={show}
			onHide={() => {
				handleClose();
				resetState();
			}}
		>
			<Modal.Header className='headerModalHistorial' closeButton>
				<Modal.Title>Ingresar compra Compra #{purchase?._id}</Modal.Title>
			</Modal.Header>
			<Modal.Body className='modalConfirmationContainer'>
				<div>
					<label htmlFor='cliente'>Cliente:</label>
					<h3>{`${purchase?.cliente?.nombre} ${purchase?.cliente?.apellido}`||""}</h3>
				</div>
				{formData.detalle?.map((producto, index) => (
					<div className='container-detalle-producto' key={index}>
						<h4>Producto {index + 1}</h4>
						<label htmlFor={`nombreProducto${index}`}>Nombre:</label>
						<h5>{producto.nombre}</h5>

						{producto.marca && (
							<>
								<label htmlFor={`nombreProducto${index}`}>Marca:</label>
								<h5>{producto.marca}</h5>
							</>
						)}
						<label htmlFor={`precioOption${index}`}>Selecciona el precio:</label>
						<select
							className='form-control'
							id={`precioOption${index}`}
							value={selectedPrices[index] || ''}
							onChange={(e) => handlePriceChange(e, index)}
						>
							<option value='precioTarjeta'>Precio Lista {producto.precioTarjeta}</option>
							<option value='precioEfectivo'>Precio Promo {producto.precioEfectivo}</option>
							{producto.precioOferta > 0 && (
								<option value='precioOferta'>Precio Oferta {producto.precioOferta}</option>
							)}
							{isSuperAdmin && <option value='precioPersonalizado'>Precio Personalizado</option>}
						</select>

						{isCustomPriceSelected === index && (
							<>
								<label htmlFor={`customPriceInput${index}`}>Precio Personalizado:</label>
								<input
									className='form-control'
									type='number'
									id={`customPriceInput${index}`}
									value={customPrices[index]}
									onChange={(e) => handleCustomPriceChange(e, index)}
								/>
							</>
						)}

						<label htmlFor={`precioProducto${index}`}>Precio elegido:</label>
						<h5>{currencyDisplay(producto.precioElegido)}</h5>

						<label htmlFor={`cantidadProducto${index}`}>Cantidad:</label>
						<input
							className='form-control'
							type='number'
							id={`cantidadProducto${index}`}
							value={producto?.cantidad?.toString()}
							onChange={(e) => handleQuantityChange(e, index)}
						/>

						<label htmlFor={`puntosProducto${index}`}>Perlas:</label>
						{isSuperAdmin ? (
							<input
								className='form-control'
								type='number'
								id={`puntosProducto${index}`}
								value={producto?.puntos?.toString()}
								onChange={(e) => handlePuntosChange(e, index)}
							/>
						) : (
							<label>{producto?.puntos}</label>
						)}
					</div>
				))}
				{
					staff?.usuarios && (
						<div>
						<label htmlFor='nombre'>Selecciona Staff:</label>
						<DropdownButton
									// className='medivarianstyle={{color:}}
									variant="success"
									id='mediosPagoDropdown'
									title={
										formData?.atendidoPor && typeof formData.atendidoPor === 'object'
											? `${staff?.usuarios?.filter(u =>  u._id  === formData?.atendidoPor?._id)[0].nombre} ${staff?.usuarios?.filter(u => u._id === formData?.atendidoPor?._id)[0].apellido}`
											: formData?.atendidoPor && typeof formData.atendidoPor === 'string' && formData !==""
											?  `${staff?.usuarios?.filter(u =>  u._id  === formData?.atendidoPor)[0].nombre} ${staff?.usuarios?.filter(u => u._id === formData?.atendidoPor)[0].apellido}`
											:"Selecciona staff"
										}
									onSelect={(eventKey) => handleStaffSelect(eventKey)}
								>
										{staff?.usuarios?.map((staff) => (
											<Dropdown.Item  key={staff._id} eventKey={staff._id} >
												{staff?.nombre?.toUpperCase()} {staff?.apellido?.toUpperCase()}
											</Dropdown.Item>
										))}
									</DropdownButton>
								</div>
					)
				}
			
				{clientInfo?.beneficios && (
					<section className='beneficiosCliente'>
						<label>
							Beneficios disponibles para cliente categoria {clientInfo?.categoria.nombre || ''}:
						</label>
					
						<select value={selectedBeneficio} onChange={handleBeneficioChange}>
							<option value=''>Selecciona un beneficio</option>
							{clientInfo?.beneficios
								?.filter((beneficio) => beneficio.disponible === true)
								.map((beneficio, index) => (
									<option key={index} value={beneficio._id}>
										{beneficio.nombre ||""}
									</option>
								))}
						</select>
					</section>
				)}
					<br></br>
				<div>
					<strong>

					<label style={{backgroundColor:"#ffff"}} htmlFor='total'>TOTAL DE LA COMPRA: {currencyDisplay(formData?.total)} </label>
				
					</strong>
				</div>
				{selectedBeneficio && (
					<>
						<div>
							<label> Beneficio seleccionado: {selectedBeneficio.nombre } </label>
							<abbr title='Eliminar beneficio seleccionado'>
								<button className='btn btn-sm  carrito' onClick={() => setSelectedBeneficio('')}>
									<i className='bx bx-trash'></i>
								</button>
							</abbr>
						</div>
						<label>
							{`Monto descontado: ${currencyDisplay(
								obtenerCostoProductosServicios(formData?.detalle) - subTotalConDescuento
							)}`}
						</label>
						<label>
							<strong>
								{`Total de la compra con descuento: ${currencyDisplay(subTotalConDescuento)}`}
							</strong>
						</label>
					</>
				)}
				<label>{`Debes asignar: ${currencyDisplay(
					subTotalConDescuento - sumaMedios(selectedMediosPago)
				)}`}</label>


				<div>
				<label style={{fontStyle:"italic"}} htmlFor='totalPuntos'>Beauti perlas: {formData?.totalPuntos}</label>
				<br></br>
					<br></br>
					<DropdownButton
						className='mediosPagoDrop'
						id='mediosPagoDropdown'
						title='Selecciona los medios de pago'
						onSelect={(eventKey) => handleMediosPagoChange(eventKey)}
					>
						{mediosPago.map((medio) => (
							<Dropdown.Item style={{backgroundColor:medio?.color||"#ffff" }}key={medio._id} eventKey={medio._id} className='itemEditPurchase'>
								{medio.nombre || ""}
							</Dropdown.Item>
						))}
					</DropdownButton>
				</div>
				{selectedMediosPago.map((medioPagoObj, index) => (
					<div key={index}>
						<label htmlFor={`montoMedioPago${index}`}>
							Monto de <strong> {medioPagoObj.nombre ||""}</strong>:
						</label>
						<abbr title='Eliminar medio de pago' style={{ cursor: 'pointer' }}>
							<i
								className='bi bi-x-circle filterOptionX'
								onClick={() => handleRemoveMedioPago(index)}
							></i>
						</abbr>
						<input
							className='form-control'
							type='number'
							id={`montoMedioPago${index}`}
							value={medioPagoObj.monto}
							onChange={(e) => handleMontoMedioPagoChange(e, index)}
						/>
						{medioPagoObj.nombre === 'GIFTCARD' && (
							<div>
								<label htmlFor='giftCardCode'>Código de Gift Card:</label>
								<input
									className='form-control'
									type='text'
									id='giftCardCode'
									value={giftCardCode}
									onChange={(e) => setGiftCardCode(e.target.value)}
								/>
								<Button onClick={handleVerifyGiftCard}>Verificar</Button>
								{/* Aquí puedes agregar una función para hacer una petición GET al servidor para verificar la gift card */}
								{giftCardInfo[0]?.vencimiento && !isGiftCardExpired(giftCardInfo[0].vencimiento) ? (
									<div>
										<label
											className='pRed'
											htmlFor='giftCardCode'
										>{`Estado: GIFTCARD VENCIDA`}</label>
									</div>
								) : (
									<div>
										<label htmlFor='giftCardCode'>{`Estado: GIFTCARD DISPONIBLE`}</label>
									</div>
								)}
								{giftCardInfo[0]?.saldoDisponible > 0 ? (
									<div>
										<label htmlFor='giftCardCode'>{`Saldo disponible: ${currencyDisplay(
											giftCardInfo[0].saldoDisponible
										)}`}</label>
									</div>
								) : giftCardCode !== '' ? (
									<div>
										<label>La giftcard ingresada no posee saldo</label>
									</div>
								) : null}
							</div>
						)}
					</div>
				))}

				{/* <div>
					<label htmlFor='totalPuntos'>Beauti perlas:</label>
					<h3>{formData?.totalPuntos}</h3>
				</div> */}
				<div>
					<label htmlFor='estadoCompra'>Estado de la compra:</label>
					<select
						className='form-control'
						name='estadoCompra'
						value={selectedEstado}
						onChange={handleEstadoChange}
					>
						<option value='PENDIENTE'>PENDIENTE</option>
						<option value='FINALIZADA'>FINALIZADA</option>
						<option value='CANCELADA'>CANCELADA</option>
					</select>
				</div>
				<div>
          <label htmlFor='notas'>Notas:</label>
          <input
            className='form-control'
            type='text'
            name='notas'
            value={formData?.notas}
            onChange={handleChange}
            placeholder='Ingresa un comentario acerca de esta venta'
          ></input>
        </div>
				{!selectedBeneficio && !validateTotal(formData?.total, selectedMediosPago) && (
					<label className='revisa'>
						La suma de los medios de pago seleccionados no coincide con el total de la compra
					</label>
				)}
				{selectedBeneficio && !validateTotal(subTotalConDescuento, selectedMediosPago) && (
					<label className='revisa'>
						La suma de los medios de pago seleccionados no coincide con el total de la compra con
						descuentos
					</label>
				)}
				<div style={{gap:"5px"}} className='divMagicoReset py-2 mt-4'>
					<Button variant='secondary' onClick={handleClose}>
						Cerrar
					</Button>
					<Button
						className='botonModalConfirmacion'
						variant='primary'
						onClick={() => handleEditPurchase(formData)}
						disabled={
							!selectedBeneficio
								? !validateTotal(formData?.total, selectedMediosPago)
								: !validateTotal(subTotalConDescuento, selectedMediosPago)
						}
					>
						Ingresar compra
						</Button>
							{isSuperAdmin && (
						<Button variant='danger' onClick={handleDelete}>
							Eliminar compra
						</Button>
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalEditPurchase;

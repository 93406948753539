import React, { useState, useEffect, useRef } from 'react';
import { Bar, Pie, Chart } from 'react-chartjs-2';
import { useMediaQuery } from 'react-responsive';
import { Form, Button } from 'react-bootstrap';
import 'chartjs-plugin-datalabels';
import { PDFDocument, rgb } from 'pdf-lib';
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	BarElement,
} from 'chart.js';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import NavBar from '../../components/navBar/NavBar';
import Footer from '../../components/footer/Footer';
import Loader from '../../components/loader/Loader';
import toast, { Toaster } from 'react-hot-toast';
import './Estadisticas.Module.css';
import { format } from 'date-fns';
import es from 'date-fns/esm/locale/es';
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const Estadisticas = () => {
	const [startDate, setStartDate] = useState(null); // Estado para la fecha de inicio
	const [endDate, setEndDate] = useState(null); // Estado para la fecha de fin
	const [estadisticas, setEstadisticas] = useState(null); // Estado para almacenar estadísticas
	const [loader, setLoader] = useState(true);
	const isMobile = useMediaQuery({ maxWidth: 768 });
	const labelMaxLength = isMobile ? 100 : 100; // Define el largo máximo del label

	const uri = process.env.REACT_APP_BACK_URL;
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const chartRefs = {}; // Objeto para mantener referencias a los gráficos
	const chartHeight = isMobile ? 400 : 300; // Altura diferente para dispositivos móviles

	const handleConsultarEstadisticas = async () => {
		try {
			// console.log(startDate, endDate);
			setLoader(true); // Mostrar el loader
			for (const chartKey in chartRefs) {
				if (chartRefs[chartKey]) {
					// No necesitas destruir las instancias de Chart.js aquí
					// chartRefs[chartKey].chartInstance.destroy();
				}
			}
			// Formatear las fechas a 'MM-DD-AAAA'
			const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : '';
			const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : '';

			const response = await axios.get(
				`${uri}/api/estadisticas?desde=${formattedStartDate}&hasta=${formattedEndDate}`,
				config
			);
			const estadisticasData = response.data; // Asume que obtienes datos estadísticos desde el backend

			setEstadisticas(estadisticasData);
			setLoader(false); // Ocultar el loader
			// No crees nuevas instancias de Chart aquí, en su lugar, configura los datos y opciones para los gráficos
			// No necesitas este bucle para crear gráficos
		} catch (error) {
			console.error('Error al obtener estadísticas', error);
		}
	};

	// Datos para el gráfico de usuarios
	const usuariosData = {
		labels: ['Clientes Activos', 'Clientes Registrados'],
		datasets: [
			{
				data: [
					estadisticas?.clientes?.usuariosActivos,
					estadisticas?.clientes?.usuariosRegistrados,
				],
				backgroundColor: ['#eb369a', '#d856ff'], // Colores para cada segmento
			},
		],
	};

	//Datos para el gráfico de mejores clientes
	const mejoresClientes = estadisticas?.mejoresClientes || [];
	const mejoresClientesData = {
		labels: mejoresClientes.map(
			(cliente) => `${cliente.nombre.toUpperCase()} ${cliente.apellido.toUpperCase()}`
		),
		datasets: [
			{
				label: 'Compras',
				data: mejoresClientes.map((cliente) => cliente.compras),
				backgroundColor: 'rgba(75, 192, 192, 0.2)',
				borderColor: 'rgba(75, 192, 192, 1)',
				borderWidth: 1,
			},
		],
	};

	//Datos para el grafico de productos mas vendidos
	const productosMasVendidos = estadisticas?.productosMasVendidos || [];
	const productosMasVendidosData = {
		labels: productosMasVendidos.map((producto) => producto.nombre),
		datasets: [
			{
				label: 'Cantidad',
				data: productosMasVendidos.map((producto) => producto.cantidad),
				backgroundColor: 'rgba(192, 75, 147, 0.2)',
				borderColor: '#c04b99',
				borderWidth: 1,
			},
		],
	};

	const options = {
		indexAxis: 'y',
		elements: {
			bar: {
				borderWidth: 2,
			},
		},
		responsive: true,
		plugins: {
			legend: {
				display: 'none',
			},
			datalabels: {
				anchor: 'center', // Ancla las etiquetas en el centro de las barras
				align: 'center', // Alinea las etiquetas al final de las barras
				color: 'black', // Color del texto de las etiquetas
				font: {
					weight: 'bold', // Puedes personalizar la fuente
				},
				formatter: (value, context) => {
					return value; // Puedes personalizar el formato de las etiquetas si es necesario
				},
			},
		},
		scales: {
			x: {
				beginAtZero: true,
			},
			y: {
				type: 'category',
				position: 'right',
				offset: true,
			},
		},
	};
	//Datos para el grafico de servicios mas vendidos
	const serviciosMasVendidos = estadisticas?.serviciosMasVendidos || [];

	const serviciosMasVendidosData = {
		labels: serviciosMasVendidos.map((servicio) => servicio.nombre),
		datasets: [
			{
				label: 'Cantidad',
				data: serviciosMasVendidos.map((servicio) => servicio.cantidad),
				backgroundColor: 'rgba(75, 192, 91, 0.2)',
				borderColor: '#4bc051',
				borderWidth: 1,
				maxBarThickness: 80, // Ajusta este valor según tus necesidades
			},
		],
	};

	//Datos para el grafico de medios de pago mas utilizados
	const mediosMasUtilizados = estadisticas?.mediosMasUtilizados || [];
	const mediosMasUtilizadosData = {
		labels: mediosMasUtilizados.map((medio) => medio.nombre),
		datasets: [
			{
				label: 'Cantidad',
				data: mediosMasUtilizados.map((medio) => medio.cantidad),
				backgroundColor: 'rgba(192, 180, 75, 0.2)',
				borderColor: '#c0804b',
				borderWidth: 1,
			},
		],
	};
	//Datos para el grafico de mini Beautiqueras
	const miniBeauti = estadisticas?.miniBeauti || [];
	const miniBeautiData = {
		labels: miniBeauti.map((mini) => mini.nombre.slice(0, labelMaxLength)),
		datasets: [
			{
				label: 'Cantidad',
				data: miniBeauti.map((mini) => mini.cantidad),
				backgroundColor: 'rgba(250, 11, 11, 0.2)',
				borderColor: '#9b0202',
				borderWidth: 1,
			},
		],
	};

	//Datos para el grafico de workShops
	const workShops = estadisticas?.workshops || [];
	const workShopsData = {
		labels: workShops.map((work) => work.nombre),
		datasets: [
			{
				label: 'Cantidad',
				data: workShops.map((work) => work.cantidad),
				backgroundColor: 'rgba(57, 11, 163, 0.2)',
				borderColor: '#b4059d',
				borderWidth: 1,
			},
		],
	};

	//Mejores días
	const diasMasUtilizados = estadisticas?.mejoresDias || [];
	const diasMasUtilizadosData = {
		labels: diasMasUtilizados.map((dia) => dia.dia),
		datasets: [
			{
				label: 'Cantidad de ventas',
				data: diasMasUtilizados.map((dia) => dia.ventas),
				backgroundColor: [
					'#11e40a',
					'#4455b6',
					'#b64444',
					'#449fb6',
					'#b68e44',
					'#b644a3',
					'#61325f',
				],

				borderWidth: 1,
			},
		],
	};
	//Marcas mas vendidas
	const marcasMasVendidas = estadisticas?.marcasMasVendidas || [];
	const marcasMasVendidasData = {
		labels: marcasMasVendidas.map((marca) => marca.marca),
		datasets: [
			{
				label: 'Ventas',
				data: marcasMasVendidas.map((marca) => marca.cantidad),
				backgroundColor: [
					'#11e40a',
					'#4455b6',
					'#b64444',
					'#449fb6',
					'#b68e44',
					'#b644a3',
					'#61325f',
					'#f75b5b',
				],

				borderWidth: 1,
			},
		],
	};

	const chartContainerRef = useRef(null);
	const chartContainerRef2 = useRef(null);
	const userListRef = useRef(null);

	const exportToPDF = async (containerRef, pdfDoc) => {
		if (containerRef.current) {
			const canvas = await html2canvas(containerRef.current);
			const imgData = canvas.toDataURL('image/png');

			const image = await pdfDoc.embedPng(imgData);

			const page = pdfDoc.addPage([canvas.width, canvas.height]);
			page.drawImage(image, {
				x: 0,
				y: 0,
				width: canvas.width,
				height: canvas.height,
			});
		}
	};

	const exportAllToPDF = async () => {
		const pdfDoc = await PDFDocument.create();

		// Exporta las imágenes de los primeros contenedores
		await exportToPDF(chartContainerRef, pdfDoc);
		await exportToPDF(chartContainerRef2, pdfDoc);

		// Obtén el texto del título y del contenido de la lista
		const userListTitle = userListRef.current.querySelector('h3').textContent;
		const userListItems = userListRef.current.querySelectorAll('li');

		// Crea una nueva página con un tamaño automático basado en el contenido
		const page = pdfDoc.addPage([612, 792]); // Tamaño estándar de una página, puedes ajustarlo según tus necesidades

		let textY = 762; // Ajusta la posición Y para que esté arriba de la página

		// Agrega el título a la nueva página
		page.drawText(userListTitle, {
			x: 10, // Ajusta la posición X según sea necesario
			y: textY, // Ajusta la posición Y para que esté arriba
			size: 18,
			color: rgb(0, 0, 0),
		});

		textY -= 20; // Ajusta el valor para el espaciado vertical

		// Agrega el contenido de la lista a la nueva página
		userListItems.forEach((item) => {
			page.drawText(item.textContent, {
				x: 10, // Ajusta la posición X según sea necesario
				y: textY,
				size: 12,
				color: rgb(0, 0, 0),
			});
			textY -= 20; // Ajusta el valor para el espaciado vertical
		});

		const pdfBytes = await pdfDoc.save();

		// Ahora puedes descargar el PDF resultante
		const blob = new Blob([pdfBytes], { type: 'application/pdf' });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = 'combinedCharts.pdf';
		link.click();
	};

	useEffect(() => {
		toast.remove();
		setTimeout(() => {
			setLoader(false);
		}, 1000);
	}, []);

	return (
		<div className='fondoStats'>
			<header>
				<NavBar />
			</header>
			{loader ? (
				<Loader />
			) : (
				<main className='mainStats'>
					<h2>Estadísticas</h2>
					<div className='containerDateStats'>
						<Form>
							<Form.Group className=' d-flex flex-column px-3 py-2' controlId='startDate'>
								<label>Fecha inicio periodo</label>
								<DatePicker
									className='form-control shadow '
									selected={startDate}
									onChange={(date) => setStartDate(date)}
									locale={es} // Establece el idioma en español
									dateFormat='dd-MM-yyyy' // Establece el formato personalizado
								/>
							</Form.Group>
							<Form.Group className='d-flex flex-column px-3 py-2' controlId='endDate'>
								<label>Fecha fin periodo</label>
								<DatePicker
									className='form-control shadow'
									selected={endDate}
									onChange={(date) => setEndDate(date)}
									locale={es} // Establece el idioma en español
									dateFormat='dd-MM-yyyy' // Establece el formato personalizado
								/>
							</Form.Group>
							<Button
								variant='secondary'
								type='submit'
								className='py-1'
								onClick={handleConsultarEstadisticas}
							>
								Consultar
							</Button>
						</Form>
					</div>
					<div
						className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 py-5 px-5'
						ref={chartContainerRef}
					>
						{mejoresClientes.length > 0 && (
							<div className='stat py-5 grafico'>
								<h3>Mejores Clientes</h3>
								<Bar
									data={mejoresClientesData}
									options={{ ...options, maintainAspectRatio: false, height: chartHeight }}
								/>
							</div>
						)}
						{productosMasVendidos.length > 0 && (
							<div className='stat py-5 grafico'>
								<h3>Productos mas vendidos</h3>
								<Bar
									data={productosMasVendidosData}
									options={{ ...options, maintainAspectRatio: false, height: chartHeight }}
								/>
							</div>
						)}

						{serviciosMasVendidos.length > 0 && (
							<div className='stat py-5 grafico'>
								<h3>Servicios mas vendidos</h3>
								<Bar
									data={serviciosMasVendidosData}
									options={{ ...options, maintainAspectRatio: false, height: chartHeight }}
								/>
							</div>
						)}
						{mediosMasUtilizados.length > 0 && (
							<div className='stat py-5 grafico'>
								<h3>Medios de pago mas utilizados</h3>
								<Bar
									data={mediosMasUtilizadosData}
									options={{ ...options, maintainAspectRatio: false, height: chartHeight }}
								/>
							</div>
						)}
						{miniBeauti.length > 0 && (
							<div className='stat py-5 grafico'>
								<h3>Mini Beautiqueras</h3>
								<Bar
									data={miniBeautiData}
									options={{ ...options, maintainAspectRatio: false, height: chartHeight }}
								/>
							</div>
						)}
						{workShops.length > 0 && (
							<div className='stat py-5 grafico'>
								<h3>Work Shops & eventos</h3>
								<Bar
									data={workShopsData}
									options={{ ...options, maintainAspectRatio: false, height: chartHeight }}
								/>
							</div>
						)}
					</div>
					<div
						className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 py-5 px-5'
						ref={chartContainerRef2}
					>
						{estadisticas?.clientes && (
							<div className='statPie py-5'>
								<h3>Usuarios(registrados/activos) </h3>
								<Pie data={usuariosData} />
							</div>
						)}
						{estadisticas?.mejoresDias && (
							<div className='statPie py-5'>
								<h3>Ventas por día </h3>
								<Pie data={diasMasUtilizadosData} />
							</div>
						)}
						{estadisticas?.marcasMasVendidas.length > 0 && (
							<div className='statPie py-5'>
								<h3>Marcas mas vendidas </h3>
								<Pie data={marcasMasVendidasData} />
							</div>
						)}
					</div>
					{estadisticas?.clientes?.usuariosConCompras &&
						estadisticas.clientes.usuariosConCompras.length > 0 && (
							<div className=' py-5 px-5' ref={userListRef}>
								<h3>Usuarios que realizaron compras en el periodo</h3>
								<ul className='usuariosConCompras' style={{ listStyleType: 'none' }}>
									{estadisticas.clientes.usuariosConCompras.map((usuario) => (
										<li key={usuario.nombre}>
											{usuario.nombre.toUpperCase()} {usuario.apellido.toUpperCase()}
										</li>
									))}
								</ul>
							</div>
						)}
					{estadisticas?.marcasMasVendidas && (
						<Button variant='primary' onClick={exportAllToPDF}>
							Exportar a PDF
						</Button>
					)}
				</main>
			)}
			<footer>
				<Footer />
			</footer>
		</div>
	);
};

export default Estadisticas;

import React from 'react';
import { Link } from 'react-router-dom';
import instagram from '../../assets/svg-redes/instagram.svg';
import tiktok from '../../assets/svg-redes/tiktok.svg';
import email from '../../assets/svg-redes/email.svg';
import whatsapp from '../../assets/svg-redes/whatsapp.svg';
import './SocialNetwork.Module.css';

function SocialNetwork() {
	return (
		<div className='container'>
			{/* <h2 className='pt-5 tituloSocial'>Beauti-Redes</h2> */}
			<div className='row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 py-5 px-5 containSocial'>
				<div className='my-2 d-flex justify-content-center'>
					<abbr title='Seguinos en Instagram para enterarte de todo!'>
						<Link
							className='px-2 py-1 bd-placeholder-img rounded-circle containSocial__img'
							target='blank'
							to='https://instagram.com/labeautiqueria?igshid=YmM0MjE2YWMzOA=='
						>
							<img
								className='bd-placeholder-img social__img'
								src={instagram}
								alt='ícono de instagram'
							/>
						</Link>
					</abbr>
				</div>
				<div className='my-2 d-flex justify-content-center'>
					<abbr title='Divertite con nosotras en TikTok'>
						<Link
						target='blank'
							className='px-2 bd-placeholder-img rounded-circle containSocial__img'
							to='https://www.tiktok.com/@labeautiqueria?_t=8dzKMyE5fNO&_r=1'
						>
							<img className='bd-placeholder-img  social__img' src={tiktok} alt='ícono de tiktok' />
						</Link>
					</abbr>
				</div>
				<div className=' my-2 d-flex justify-content-center'>
					<abbr title='Envianos un correo para despejar tus dudas!'>
						<Link
						target='blank'
							className='px-2 bd-placeholder-img rounded-circle containSocial__img'
							to='mailto:mgstraface@gmail.com'
						>
							<img className='bd-placeholder-img  social__img' src={email} alt='ícono de email' />
						</Link>
					</abbr>
				</div>
				<div className='my-2 d-flex justify-content-center'>
					<abbr title='Hablemos por Whatsapp!'>
						<Link
						target='blank'
							className='px-2 bd-placeholder-img rounded-circle containSocial__img'
							to='https://wa.me/message/RUJEODHSOVNFK1'
						>
							<img
								className='bd-placeholder-img  social__img'
								src={whatsapp}
								alt='ícono de whatsapp'
							/>
						</Link>
					</abbr>
				</div>
			</div>
		</div>
	);
}

export default SocialNetwork;
